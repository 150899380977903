import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import search from "../../assets/search.svg";
import '../auth/element.css';
import { callApi } from '../utills/api';
import { showAlert, showError, showSuccess } from '../utills/sweetAlert';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import TopNavBar from '../Layout/TopNavBar';
import CustomerExcel from './customerexcel';

const { RangePicker } = DatePicker;

interface Vendor {
  id: number;
  name: string;
  create_date: string;
}

const CustomerManagement: React.FC = () => {
    const [siteModal, setSiteShow] = useState(false);
const [siteDetails, setSiteDetails] = useState<any[]>([]); 
    const siteShow = () => setSiteShow(true);
    const siteClose = () => {
      setState((prevState) => ({
        ...prevState,
        id:"",
        name: "",
      }));
        setSiteShow(false);
    }

  const [state, setState] = useState({
    id:"",
    name:"",
  
});

    const siteOnchange = (event: any) => {
        const { name, value } = event.target;
        setState((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    }

    const handleSubmit = (event: any) => {
        const form = event.target.form;
        if (form.checkValidity() === false) {
            console.log("Form validation failed.");
            return;
        }
        event.preventDefault();
        const trimmedSiteName = state.name.trim();
        // const trimmedLocation = state.Location.trim();
        if (trimmedSiteName ) {
            let request = {
                name:trimmedSiteName,
                // location:trimmedLocation
            }
           
        callApi("POST", "customer_insert.php", request)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            showSuccess("Success!", "customer Created Successfully!");
            setState((prevState) => ({
                ...prevState,
                name: "",
                // Location: "",
              }));
              setSiteShow(false);
              tableSiteShow();
        }
    })
    .catch((err: any) => {
      console.error(
        "API call error:",
        err.response ? err.response.data.error : err.message
      );
      showError(
        "Failed",
        err.response ? err.response.data.error : err.message
      );
    });  
  } else {
    showError(
        "Failed", "Empty Space Not Allowed");
        setState((prevState) => ({
          ...prevState,
          name: "",
          // Location: "",
        }));
}     
    }

    useEffect(() => {
        tableSiteShow();
      }, []);

      const tableSiteShow = () =>{
        callApi("GET", "customer_select.php", "")
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            console.log("response--->",response.list);
            setSiteDetails(response.list);
            console.log("siteDetails--->",siteDetails);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
      }

            
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredData = siteDetails.filter((item: any) =>
    Object.values(item).some((value) =>
      (value as string).toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );
  
  // Calculate pagination for filtered data
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);
  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };
  
  // Calculate the display range for the items
  const startItem = offset + 1;
  const endItem = Math.min(offset + itemsPerPage, filteredData.length);


          const handleEdit = (id: any) => {
            let req = {
                id: id,
              };
              callApi("POST", "customer_id_select.php", req)
              .then((res) => res.data)
              .then((response) => {
                if (response) {
                    setState((prevState) => ({
                        ...prevState,
                        name: response.list[0].name,
                        // Location: response.list[0].location,
                        id:response.list[0].id,
                      }));
                  setSiteShow(true)
                }
              })
              .catch((err: any) => {
                console.error(
                  "API call error:",
                  err.response ? err.response.data.error : err.message
                );
                showError(
                  "Failed",
                  err.response ? err.response.data.error : err.message
                );
              });
        }

        const handleUpdate = (event: any) => {
          const form = event.target.form;
          event.preventDefault();
          let req = {
          id: state.id,
          name:state.name,
          // location:state.Location
            };

            callApi("POST", "customer_edit.php", req)
            .then((res) => res.data)
            .then((response) => {
              if (response) {
                  showSuccess("Success!", "customer Updated Successfully!");
                  setState((prevState) => ({
                      ...prevState,
                      VendorName: "",
                      ProductService:"",
                      VendorId:"",
                    }));
                    setSiteShow(false)
                    tableSiteShow();
              }
            })
            .catch((err: any) => {
              console.error(
                "API call error:",
                err.response ? err.response.data.error : err.message
              );
              showError(
                "Failed",
                err.response ? err.response.data.error : err.message
              );
            });
      }

        const handleDelete = async (id: any) => {
          const isComfirm = await showAlert(
            "Please Confirm",
            "Are you sure you want to Delete?",
            "Yes",
            "No"
          );
          if (isComfirm) {
          let req = {
              id: id,
            };
            callApi("POST", "customer_delete.php", req)
            .then((res) => res.data)
            .then((response) => {
              if (response) {
            showSuccess("Success!", "customer Deleted Successfully!");
                tableSiteShow();
              }
            })
            .catch((err: any) => {
              console.error(
                "API call error:",
                err.response ? err.response.data.error : err.message
              );
              showError(
                "Failed",
                err.response ? err.response.data.error : err.message
              );
            });
          }
      }

      const [filterTemp, setFilterTemp] = useState<{
        startDate: string | null;
        endDate: string | null;
      }>({
        startDate: null,
        endDate: null,
      });

      useEffect(() => {
        if(filterTemp.startDate && filterTemp.endDate){
            let req = {
                from_date:filterTemp.startDate,
                to_date:filterTemp.endDate
              };       
            callApi("POST", "customer_select.php", req)
            .then((res) => res.data)
            .then((response) => {
              if (response) {
                setSiteDetails(response.list);
              }
            })
            .catch((err: any) => {
              console.error(
                "API call error:",
                err.response ? err.response.data.error : err.message
              );
              showError(
                "Failed",
                err.response ? err.response.data.error : err.message
              );
            });
        }else{
          tableSiteShow(); 
        }
         }, [filterTemp]);

         const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]); // Array to store selected vendor IDs
const [selectAll, setSelectAll] = useState(false); 

         const isSelected = (item: any) => {
          return selectedVendors.some((vendor) => vendor.id === item.id);
        };
      
        const handleCheckboxChange = (item: any) => {
          setSelectedVendors((prevSelected) =>
            isSelected(item)
              ? prevSelected.filter((vendor) => vendor.id !== item.id) // Deselect the vendor
              : [...prevSelected, item] // Select the vendor
          );
        };

        
    useEffect(() => {
      console.log("selectedVendors--->",selectedVendors);
    }, [selectedVendors]);
      
        const handleSelectAllChange = () => {
          if (selectAll) {
            // Deselect all
            setSelectedVendors([]);
          } else {
            // Select all vendor objects
            setSelectedVendors(siteDetails);
          }
          setSelectAll(!selectAll);
        };

    return (
        <>
        <div>
                  <div className="mobile_nav_bar">
        <TopNavBar />
      </div>
            <div className="">
            <Row className='webview'>
                    <div className="category-header">
                    <Col lg="8" md="8">
                            <h3 className="category-title">
                                Customer Management
                            </h3>
                        </Col>
                        <Col lg="4" md="4"  className="headbtn">
                            <button className="cashinbtn"
                                onClick={siteShow}>
                                Add Customer
                            </button>
                            <div className="search-bar">
                                <img
                                    src={search}
                                    alt="search-icon"
                                    style={{ width: "22px" }}
                                />
                                <input type="text" placeholder="Search..."
                                 onChange={(e) => handleSearch(e.target.value)} />
                            </div>
                        </Col>
                    </div>
                </Row>
                <Row className='mblview'>
                    <div className="category-header">
                    <div className='mblsitecol'>
                        <Col lg="9" md="9">
                            <h3 className="category-title">
                                customer
                            </h3>
                        </Col>
                        <Col lg="1" md="1" className="">
                            <button className="cashinbtn"
                                onClick={siteShow}>
                                Add customer
                            </button>
                        </Col>
</div>
                        <Col lg="2" md="2" className='flexcenter'>
                            <div className="search-bar">
                                <img
                                    src={search}
                                    alt="search-icon"
                                    style={{ width: "22px" }}
                                />
                                <input type="text" placeholder="Search..." />

                            </div>
                        </Col>
                    </div>
                </Row>
            </div>

            <div className="element-container">
            <Row className='pb-2'>
            <Col lg="8" md="8">
  <h4 className="category-title pb-2">
    <FontAwesomeIcon icon={faFile} className="icon" />
    Customer Details
  </h4>
</Col>
<Col lg="4" md="4" className="dateexport">
        <RangePicker
        value={[
          filterTemp.startDate ? dayjs(filterTemp.startDate) : null,
          filterTemp.endDate ? dayjs(filterTemp.endDate) : null,
        ]}
        onChange={(dates) => {
          setFilterTemp({
            startDate: dates?.[0] ? dates[0].format('YYYY-MM-DD') : null,
            endDate: dates?.[1] ? dates[1].format('YYYY-MM-DD') : null,
          });
        }}
        format="DD-MM-YYYY"
        style={{ width: '100%' }}
      />
       
          <CustomerExcel data={selectedVendors.length > 0 ? selectedVendors :filteredData} />
          </Col>
      </Row>
      {filteredData.length > 0 ? (
      <div className="table-container">
            <table className="custom-table">
                <thead>
                    <tr>
                    <th> <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllChange}
                /></th>
                        <th>Customer Name</th>
                        {/* <th>Location</th> */}
                        <th>Created Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {currentData.map((item) => (
        <tr key={item.id}>
               <td>
              
                  <input
                    type="checkbox"
                    checked={isSelected(item)}
                    onChange={() => handleCheckboxChange(item)} // Pass the entire vendor object
                  />
               </td>
                            <td className='boldtd'>{item.name}</td>
                            
                            <td>{dayjs(item.create_date).format('DD-MM-YYYY')}</td>
                            <td>
                              <div className='webview'>
                            <Dropdown align="end">
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        style={{ border: "none", background: "transparent" }}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu
                       id="webdropdown">
                        <Dropdown.Item      
                          onClick={() => handleEdit(item.id)}  
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item 
                         onClick={() => handleDelete(item.id)} 
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    </div>
                    <div className='mblview'>
                            <Dropdown align="end">
                      <Dropdown.Toggle
                        variant="link"
                        id="dropdown-basic"
                        style={{ border: "none", background: "transparent" }}
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item      
                          onClick={() => handleEdit(item.id)}  
                        >
                          Edit
                        </Dropdown.Item>
                        <Dropdown.Item 
                         onClick={() => handleDelete(item.id)} 
                        >
                          Delete
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                    </div>
                    </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="pagination-container">
                <div className="pagination-info">
                {`${startItem} - ${endItem} of ${filteredData.length}`}
                </div>
                <ReactPaginate
        previousLabel={'<'}
        nextLabel={'>'}
        breakLabel={'...'}
        pageCount={pageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={handlePageClick}
        containerClassName={'pagination'}
        activeClassName={'active-page'}
        pageClassName={'page-item'}
        previousClassName={'prev-next'}
        nextClassName={'prev-next'}
      />
            </div>
        </div>
 ) : (
    <p className='nodata'>Data Not Found</p>
  )}
        </div>
        </div>
        
        <Modal show={siteModal} onHide={siteClose}>
                <Modal.Header closeButton>
                    <h4 className='category-title '>Customer Form</h4>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="loginform">

                            <div className="labeldiv">
                                <label className="label">Customer Name*</label>
                                <input
                                    type="text"
                                    className="form-control bgborder"
                                    placeholder="Enter Customer Name"
                                    name="name"
                                    minLength={2}
                                    maxLength={50}
                                    onChange={siteOnchange}
                                    pattern="^[A-Za-z0-9@#$_%&-]+( [A-Za-z0-9@#$_%&-]+)*$"
                                    value={state.name}
                                    required />
                            </div>
                            {/* <div className="labeldiv">
                                <label className="label">Location *</label>
                                <input
                                    type="text"
                                    className="form-control bgborder"
                                    placeholder="Enter Location"
                                    name="Location"
                                    minLength={2}
                                    onChange={siteOnchange}
                                    value={state.Location}
                                    pattern="^[A-Za-z0-9@#$_%&-]+( [A-Za-z0-9@#$_%&-]+)*$"
                                    maxLength={100}
                                    required />
                            </div> */}
                            <div className="savenew">
                            
                            {state.id ? (
                                    <>
                                     <button
                                    className="btn btn-primary modalsubbutton mt-3 mb-3"
                                    type="button"
                                    onClick={handleUpdate}
                                >
                                    Update
                                </button>
                                    </>
                                ):(
                                <button
                                    className="btn btn-primary modalsubbutton mt-3 mb-3"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Save
                                </button>
                                     )}
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal></>
    )
}

export default CustomerManagement