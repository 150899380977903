import React, { useEffect, useState } from 'react'
import { Button, Col, Dropdown, Form, Modal, Row } from 'react-bootstrap'
import search from "../../assets/search.svg";
import '../auth/element.css';
import { callApi } from '../utills/api';
import { showAlert, showError, showSuccess } from '../utills/sweetAlert';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { faFile, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactPaginate from 'react-paginate';
import TopNavBar from '../Layout/TopNavBar';
import SiteExcel from './siteexcel';

const { RangePicker } = DatePicker;

interface Vendor {
  id: number;
  site_name: string;
  location: string;
  create_date: string;
  customer_idfk: string;
}

const SiteMaster: React.FC = () => {
  const [siteModal, setSiteShow] = useState(false);
  const [siteDetails, setSiteDetails] = useState<any[]>([]);
  const siteShow = () => setSiteShow(true);
  const siteClose = () => {
    setState((prevState) => ({
      ...prevState,
      SiteId: "",
      SiteName: "",
      Location: "",
      customer_idfk: "",
    }));
    setSiteShow(false);
  }

  const [state, setState] = useState({
    SiteId: "",
    SiteName: "",
    Location: "",
    customer_idfk: "",
    CustomerOptionselect: [],
  });

  const siteOnchange = (event: any) => {
    const { name, value } = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = (event: any) => {
    const form = event.target.form;
    if (form.checkValidity() === false) {
      console.log("Form validation failed.");
      return;
    }
    event.preventDefault();
    const trimmedSiteName = state.SiteName.trim();
    const trimmedLocation = state.Location.trim();
    const trimmedCustomer = state.customer_idfk.trim();
    if (trimmedSiteName && trimmedLocation && trimmedCustomer) {
      let request = {
        site_name: trimmedSiteName,
        location: trimmedLocation,
        customer: trimmedCustomer
      }
      callApi("POST", "site_insert.php", request)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            showSuccess("Success!", "Site Created Successfully!");
            setState((prevState) => ({
              ...prevState,
              SiteName: "",
              Location: "",
              customer_idfk: "",
            }));
            setSiteShow(false);
            tableSiteShow();
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      showError(
        "Failed", "Empty Space Not Allowed");
      setState((prevState) => ({
        ...prevState,
        SiteName: "",
        Location: "",
        customer_idfk: "",

      }));
    }
  }

  useEffect(() => {
    tableSiteShow();
  }, []);

  const tableSiteShow = () => {
    callApi("GET", "site_select.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          console.log("response--->", response.list);
          setSiteDetails(response.list);
          console.log("siteDetails--->", siteDetails);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  }


  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 10;
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  const filteredData = siteDetails.filter((item: any) =>
    Object.values(item).some((value) =>
      (value as string).toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  // Calculate pagination for filtered data
  const pageCount = Math.ceil(filteredData.length / itemsPerPage);
  const offset = currentPage * itemsPerPage;
  const currentData = filteredData.slice(offset, offset + itemsPerPage);
  const handlePageClick = (event: { selected: number }) => {
    setCurrentPage(event.selected);
  };

  // Calculate the display range for the items
  const startItem = offset + 1;
  const endItem = Math.min(offset + itemsPerPage, filteredData.length);


  const handleEdit = (id: any) => {
    let req = {
      site_id: id,
    };
    callApi("POST", "site_id_select.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState) => ({
            ...prevState,
            SiteName: response.list[0].site_name,
            Location: response.list[0].location,
            customer_idfk: response.list[0].customer_idfk,
            SiteId: response.list[0].id,
          }));
          setSiteShow(true)
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  }

  const handleUpdate = (event: any) => {
    const form = event.target.form;
    event.preventDefault();
    let req = {
      id: state.SiteId,
      site_name: state.SiteName,
      location: state.Location,
      customer: state.customer_idfk
    };

    callApi("POST", "site_edit.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          showSuccess("Success!", "Site Updated Successfully!");
          setState((prevState) => ({
            ...prevState,
            SiteId: "",
            SiteName: "",
            Location: "",
            customer_idfk: "",
            // VendorName: "",
            // ProductService: "",
            // VendorId: "",
          }));
          setSiteShow(false)
          tableSiteShow();
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  }

  const handleDelete = async (id: any) => {
    const isComfirm = await showAlert(
      "Please Confirm",
      "Are you sure you want to Delete?",
      "Yes",
      "No"
    );
    if (isComfirm) {
      let req = {
        id: id,
      };
      callApi("POST", "site_delete.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            showSuccess("Success!", "Site Deleted Successfully!");
            tableSiteShow();
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    }
  }

  const [filterTemp, setFilterTemp] = useState<{
    startDate: string | null;
    endDate: string | null;
  }>({
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    if (filterTemp.startDate && filterTemp.endDate) {
      let req = {
        from_date: filterTemp.startDate,
        to_date: filterTemp.endDate
      };
      callApi("POST", "site_select.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setSiteDetails(response.list);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
    } else {
      tableSiteShow();
    }
  }, [filterTemp]);

  const [selectedVendors, setSelectedVendors] = useState<Vendor[]>([]); // Array to store selected vendor IDs
  const [selectAll, setSelectAll] = useState(false);

  const isSelected = (item: any) => {
    return selectedVendors.some((vendor) => vendor.id === item.id);
  };

  const handleCheckboxChange = (item: any) => {
    setSelectedVendors((prevSelected) =>
      isSelected(item)
        ? prevSelected.filter((vendor) => vendor.id !== item.id) // Deselect the vendor
        : [...prevSelected, item] // Select the vendor
    );
  };


  useEffect(() => {
    console.log("selectedVendors--->", selectedVendors);
  }, [selectedVendors]);

  const handleSelectAllChange = () => {
    if (selectAll) {
      // Deselect all
      setSelectedVendors([]);
    } else {
      // Select all vendor objects
      setSelectedVendors(siteDetails);
    }
    setSelectAll(!selectAll);
  };

  callApi("GET", "customer_select_dropdown.php", "")
    .then((res) => res.data)
    .then((response) => {
      if (response) {
        setState((prevState) => ({
          ...prevState,
          CustomerOptionselect: response.data
        }));
      }
    })
    .catch((err: any) => {
      console.error(
        "API call error:",
        err.response ? err.response.data.error : err.message
      );
      showError(
        "Failed",
        err.response ? err.response.data.error : err.message
      );
    });
  return (
    <>
      <div>
        <div className="mobile_nav_bar">
          <TopNavBar />
        </div>
        <div className="">
          <Row className='webview'>
            <div className="category-header">
              <Col lg="8" md="8">
                <h3 className="category-title">
                  Site Master
                </h3>
              </Col>
              <Col lg="4" md="4" className="headbtn">
                <button className="cashinbtn"
                  onClick={siteShow}>
                  Add Site
                </button>
                <div className="search-bar">
                  <img
                    src={search}
                    alt="search-icon"
                    style={{ width: "22px" }}
                  />
                  <input type="text" placeholder="Search..."
                    onChange={(e) => handleSearch(e.target.value)} />
                </div>
              </Col>
            </div>
          </Row>
          <Row className='mblview'>
            <div className="category-header">
              <div className='mblsitecol'>
                <Col lg="9" md="9">
                  <h3 className="category-title">
                    Site Master
                  </h3>
                </Col>
                <Col lg="1" md="1" className="">
                  <button className="cashinbtn"
                    onClick={siteShow}>
                    Add Site
                  </button>
                </Col>
              </div>
              <Col lg="2" md="2" className='flexcenter'>
                <div className="search-bar">
                  <img
                    src={search}
                    alt="search-icon"
                    style={{ width: "22px" }}
                  />
                  <input type="text" placeholder="Search..." />

                </div>
              </Col>
            </div>
          </Row>
        </div>

        <div className="element-container">
          <Row className='pb-2'>
            <Col lg="8" md="8">
              <h4 className="category-title pb-2">
                <FontAwesomeIcon icon={faFile} className="icon" />
                Site Details
              </h4>
            </Col>
            <Col lg="4" md="4" className="dateexport">
              <RangePicker
                value={[
                  filterTemp.startDate ? dayjs(filterTemp.startDate) : null,
                  filterTemp.endDate ? dayjs(filterTemp.endDate) : null,
                ]}
                onChange={(dates) => {
                  setFilterTemp({
                    startDate: dates?.[0] ? dates[0].format('YYYY-MM-DD') : null,
                    endDate: dates?.[1] ? dates[1].format('YYYY-MM-DD') : null,
                  });
                }}
                format="DD-MM-YYYY"
                style={{ width: '100%' }}
              />

              <SiteExcel data={selectedVendors.length > 0 ? selectedVendors : filteredData} />
            </Col>
          </Row>
          {filteredData.length > 0 ? (
            <div className="table-container">
              <table className="custom-table">
                <thead>
                  <tr>
                    <th> <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                    /></th>
                    <th>Site Name</th>
                    <th>Location</th>
                    <th>Customer Name</th>
                    <th>Created Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.map((item) => (
                    <tr key={item.id}>
                      <td>

                        <input
                          type="checkbox"
                          checked={isSelected(item)}
                          onChange={() => handleCheckboxChange(item)} // Pass the entire vendor object
                        />
                      </td>
                      <td className='boldtd'>{item.site_name}</td>
                      <td>
                        <span >
                          {item.location}
                        </span>
                      </td>
                      {/* <td className='boldtd'>{item.customer_name}</td> */}
                      <td>
                        <span style={{ backgroundColor: item.color }}>
                          {item.customer_idfk}
                        </span>
                      </td>
                      <td>{dayjs(item.create_date).format('DD-MM-YYYY')}</td>
                      <td>
                        <div className='webview'>
                          <Dropdown align="end">
                            <Dropdown.Toggle
                              variant="link"
                              id="dropdown-basic"
                              style={{ border: "none", background: "transparent" }}
                            ></Dropdown.Toggle>
                            <Dropdown.Menu
                              id="webdropdown">
                              <Dropdown.Item
                                onClick={() => handleEdit(item.id)}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                        <div className='mblview'>
                          <Dropdown align="end">
                            <Dropdown.Toggle
                              variant="link"
                              id="dropdown-basic"
                              style={{ border: "none", background: "transparent" }}
                            ></Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => handleEdit(item.id)}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => handleDelete(item.id)}
                              >
                                Delete
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="pagination-container">
                <div className="pagination-info">
                  {`${startItem} - ${endItem} of ${filteredData.length}`}
                </div>
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={3}
                  onPageChange={handlePageClick}
                  containerClassName={'pagination'}
                  activeClassName={'active-page'}
                  pageClassName={'page-item'}
                  previousClassName={'prev-next'}
                  nextClassName={'prev-next'}
                />
              </div>
            </div>
          ) : (
            <p className='nodata'>Data Not Found</p>
          )}
        </div>
      </div>

      <Modal show={siteModal} onHide={siteClose}>
        <Modal.Header closeButton>
          <h4 className='category-title '>Site Form</h4>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="loginform">

              <div className="labeldiv">
                <label className="label">Site Name*</label>
                <input
                  type="text"
                  className="form-control bgborder"
                  placeholder="Enter Site Name"
                  name="SiteName"
                  minLength={2}
                  maxLength={50}
                  onChange={siteOnchange}
                  pattern="^[A-Za-z0-9@#$_%&-]+( [A-Za-z0-9@#$_%&-]+)*$"
                  value={state.SiteName}
                  required />
              </div>
              <div className="labeldiv">
                <label className="label">Location *</label>
                <input
                  type="text"
                  className="form-control bgborder"
                  placeholder="Enter Location"
                  name="Location"
                  minLength={2}
                  onChange={siteOnchange}
                  value={state.Location}
                  pattern="^[A-Za-z0-9@#$_%&-]+( [A-Za-z0-9@#$_%&-]+)*$"
                  maxLength={100}
                  required />
              </div>
              {/* <div className="labeldiv">
                                <label className="label">Customer Name*</label>
                                <input
                                    type="text"
                                    className="form-control bgborder"
                                    placeholder="Enter Site Name"
                                    name="customer"
                                    minLength={2}
                                    maxLength={50}
                                    onChange={siteOnchange}
                                    pattern="^[A-Za-z0-9@#$_%&-]+( [A-Za-z0-9@#$_%&-]+)*$"
                                    value={state.SiteName}
                                    required />
                            </div> */}
              <div className="labeldiv">
                <label className="label">Customer*</label>
                <Form.Select
                  aria-label="Default select example"
                  name="customer_idfk"
                  required
                  onChange={siteOnchange}
                  value={state.customer_idfk}
                >
                  <option value="">Select Customer</option>
                  {state.CustomerOptionselect.map((category: any) => (
                    <option key={category.key} value={category.key}>
                      {category.label}
                    </option>
                  ))}
                </Form.Select>
              </div>
              <div className="savenew">

                {state.SiteId ? (
                  <>
                    <button
                      className="btn btn-primary modalsubbutton mt-3 mb-3"
                      type="button"
                      onClick={handleUpdate}
                    >
                      Update
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-primary modalsubbutton mt-3 mb-3"
                    type="submit"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal></>
  )
}

export default SiteMaster