import axios from "axios";

export const API_ENDPOINT = "https://letoinfra.zerame.com/api/";
// export const API_ENDPOINT = "http://localhost/assetts_letino/";




export async function callApi(method: string, path: string, data?: any) {
  const authKey = sessionStorage.getItem('authkey');

  const headers = {
    'Authorization': `Bearer ${authKey}`,
    'Content-Type': 'application/json', 
    'Origin': window.location.origin 
  };

  try {
    if (method.toUpperCase() === "POST") {
      return await axios.post(API_ENDPOINT + path, data, { headers });
    } else if (method.toUpperCase() === "DELETE") {
      return await axios.delete(API_ENDPOINT + path, { headers });
    } else {
      return await axios.get(API_ENDPOINT + path, { headers });
    }
  } catch (error) {
    console.error("API call error:", error);
    if (axios.isAxiosError(error) && error.response?.status === 401) {
      window.location.href = "/"; // or use a router method if in a React app
    }
    throw error;
  }
}
