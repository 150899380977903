import { faCircleDown, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

interface DataItem {
    name: string;
    create_date:string;
}


interface Props {
    data: DataItem[];
}


const CustomerExcel: React.FC<Props> = ({ data }) => {
    const csvData = data.map((item :any, index :any) => {
       
        return {
            "Customer name": item.name,
            // "Location":item.location,
            "Created Date":item.create_date,
        };
    });

    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

    return (
        <CSVLink data={csvData}  filename={`Customer Report - ${formattedDate}.csv`}>
                <Button className='exportbtn' >
              <FontAwesomeIcon icon={faDownload} className="icon" />
              Export
            </Button>
        </CSVLink>
    );
};

export default CustomerExcel;
