import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Button } from 'react-bootstrap';
import { CSVLink } from 'react-csv';

interface DataItem {
    date: string;
    particular: string;
    amount: string;
    payment_type: string;
    sitename: string;
    customer_idfk:string;
    vendor_idfk:string;

    type: string;  // Add type for distinguishing between CashIn and CashOut
}

interface Props {
    data: DataItem[];
}

const ReportExcel: React.FC<Props> = ({ data }) => {
    // Calculate totals for Cash In and Cash Out
    const cashInTotal = data
        .filter(item => item.type === "CashIn")
        .reduce((sum, item) => sum + parseFloat(item.amount || '0'), 0);

    const cashOutTotal = data
        .filter(item => item.type === "CashOut")
        .reduce((sum, item) => sum + parseFloat(item.amount || '0'), 0);

    // Map the data for CSV export, separating Cash IN and Cash OUT values
    const csvData = data.map((item) => ({
        "Date": item.date,
        "Particulars": item.particular,
        "Cash IN": item.type === "CashIn" ? item.amount : '',
        "Cash Out": item.type === "CashOut" ? item.amount : '',
        "Payment Mode": item.payment_type,
        "Site": item.sitename,
        "customer": item.customer_idfk,
        "vendor": item.vendor_idfk,


        
    }));

    // Add the total row at the end of csvData
    csvData.push({
        "Date": "",
        "Particulars": "Total Value",
        "Cash IN": cashInTotal.toFixed(2),
        "Cash Out": cashOutTotal.toFixed(2),
        "Payment Mode": "",
        "Site": "",
        "customer": "",
        "vendor": "",
    });

    // Get current date for filename
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1;
    const day = currentDate.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

    return (
        <CSVLink data={csvData} filename={`Report - ${formattedDate}.csv`}>
            <Button className='exportbtn'>
                <FontAwesomeIcon icon={faDownload} className="icon" />
                Export
            </Button>
        </CSVLink>
    );
};

export default ReportExcel;
